import '../../css/SnippetComponent.css'

import Card from 'react-bootstrap/Card'
import CardViewButtons from './CardViewButtons'
import React from 'react'
import ReactMarkdown from 'react-markdown'

const CardViewable = ({ item, stepNumber, totalSteps, setEditable, onDeleteClick, onNewClick }) => {

    return (
        <>
            <Card>
                <Card.Header><h6>{item.title}   [{stepNumber + 1}/{totalSteps}]</h6></Card.Header>
                <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Text as="span" onClick={() => setEditable(stepNumber)}>
                        <ReactMarkdown>
                            {item.step}
                        </ReactMarkdown>
                    </Card.Text>

                </Card.Body>
                <Card.Footer className="text-muted">

                    <CardViewButtons setEditable={setEditable}
                        index={stepNumber}
                        onDeleteClick={onDeleteClick}
                        onNewClick={onNewClick} />

                </Card.Footer>
            </Card>
        </>
    );

}

export default CardViewable;