
import React from 'react'
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../css/SnippetComponent.css'

const CardConnector = () => (
    <>
        <div className="vl"></div>
        <div className="arrow-icon">
            <FontAwesomeIcon icon={faAngleDoubleDown} size="2x" className="arrow-icon" />
        </div>
        <div className="vl"></div>
    </>

);

export default CardConnector