import { applyMiddleware, combineReducers, createStore } from 'redux';
import { snippets, workingSnippet } from './reducers';

import thunk from 'redux-thunk';

const rootReducer = combineReducers({
    snippets,
    workingSnippet,
});

export const configureStore = () => createStore(rootReducer, applyMiddleware(thunk));