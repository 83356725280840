import React, { useEffect, useRef, useState } from 'react'
import { getOneSnippet, getOneSnippetLoading } from '../../redux/selectors'

import CardConnector from './CardConnector'
import CardEditable from './CardEditable'
import CardViewable from './CardViewable'
import { connect } from 'react-redux';

const SnippetCard = ({ workingSnippet, snippetId, isLoading, setStepsInParent }) => {


    var [mysteps, setSteps] = useState([]);


    const useIsMounted = () => {
        const isMounted = useRef(false);
        useEffect(() => {
            isMounted.current = true;
            return () => (isMounted.current = false);
        }, []);
        return isMounted;
    };

    const isMounted = useIsMounted();
    useEffect(() => {

        // Only if loading is false and still mounted
        if (isLoading === false && isMounted.current) {
            if (workingSnippet && snippetId !== "") {
                setSteps(workingSnippet.steps);
            } else
                setSteps([{
                    title: "",
                    step: ""
                }]);
        }
    }, [workingSnippet, isMounted, isLoading, snippetId]);


    var arr = [];;
    for (var j = 0; mysteps && j < mysteps.length; ++j) {
        arr[j] = false;
    }
    var [isEditableList, setEditableList] = useState([]);
    // for (var j = 0; mysteps && j < mysteps.length; ++j)
    //     isEditableList[j] = "false";

    // isEditableList[setEditIndex] = "true";
    var setEditable = (index, val) => {

        let newArr = [...isEditableList];

        newArr[index] = val;
        setEditableList(newArr);
    }

    var emptyObject = {

        title: '',
        step: ''
    }

    if (mysteps === undefined) {
        return null;
    } else if (isLoading)
        return (<div>Loading...</div>)

    var count = 100;
    return (
        <React.Fragment key={count++}>
            {
                mysteps.map((item, index) =>
                    <React.Fragment key={count++}>
                        <CardConnector />
                        {isEditableList[index] ?

                            <CardEditable item={item}
                                stepNumber={index}
                                setEditable={setEditable}
                                onConfirmClick={(index, title, step) => {
                                    mysteps[index].title = title;
                                    mysteps[index].step = step;
                                    setStepsInParent(mysteps);
                                    setSteps([...mysteps]);
                                }}
                            />
                            :
                            <CardViewable item={item}
                                stepNumber={index}
                                setEditable={setEditable}
                                totalSteps={mysteps.length}
                                onDeleteClick={(index) => {
                                    mysteps.splice(index, 1);
                                    if (mysteps.length === 0)
                                        mysteps.push(emptyObject);
                                    setStepsInParent(mysteps);
                                    setSteps([...mysteps]);
                                }}
                                onNewClick={(index) => {
                                    mysteps.splice(index + 1, 0, emptyObject);
                                    setStepsInParent(mysteps);
                                    setSteps([...mysteps]);
                                }}
                            />

                        }


                    </React.Fragment>
                )
            }
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    workingSnippet: getOneSnippet(state),
    isLoading: getOneSnippetLoading(state),
});

export default connect(mapStateToProps)(SnippetCard);