import { faCheck, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons'

import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip'

const CardEditButtons = ({ setEditable, index, onConfirmClick, onInfoClick }) => {



    return (
        <>
            <Button variant="outline-success"
                onClick={() => {

                    setEditable(index, false)
                    onConfirmClick(index);
                }}>

                <OverlayTrigger
                    placement='bottom'
                    overlay={
                        <Tooltip id={`tooltip-save`}>
                            Save
                    </Tooltip>
                    }
                >
                    <FontAwesomeIcon icon={faCheck} size="1x" />
                </OverlayTrigger>
            </Button>{' '}
            <Button variant="outline-danger" onClick={() => setEditable(index, false)}>
                <OverlayTrigger
                    placement='bottom'
                    overlay={
                        <Tooltip id={`tooltip-cancel`}>
                            Cancel
                    </Tooltip>
                    }
                >
                    <FontAwesomeIcon icon={faTimes} size="1x" />
                </OverlayTrigger>
            </Button>{' '}
            <Button variant="outline-info" onClick={() => onInfoClick(true)}>
                <OverlayTrigger
                    placement='bottom'
                    overlay={
                        <Tooltip id={`tooltip-info`}>
                            Learn how to add formatting to your text.
                    </Tooltip>
                    }
                >
                    <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                </OverlayTrigger>
            </Button>{' '}

        </>
    );
}

export default CardEditButtons;