exports.helpText = `This Markdown cheat sheet provides a quick overview of all the Markdown syntax elements that you can use to create your snippet text. 

---

### Heading
# \\# H1
## \\#\\# H2
### \\#\\#\\# H3
---

### Bold

**\\*\\*bold text\\*\\***

---

### Italic

*\\*italicized text\\**

---

### Blockquote

\\> blockquote

---
### Ordered List
1. First item
2. Second item
3. Third item
---
### Unordered List
\\- First item
\\- Second item
\\- Third item

- First item
- Second item
- Third item
---

### Code

  \u0060\u0060  \`code\` \u0060\u0060

---
### Horizontal Rule
\\-\\-\\-

---

### Link

\\[title\\]\\(https://www.securitysynergy.io\\)
[title](https://www.securitysynergy.io)

---
`