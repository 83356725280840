import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Footer from './components/Footer';
import NavBarComponent from './components/NavBarComponent';
import React from 'react';
import RouterComponent from './components/RouterComponent';

function App() {
  return (
    <>

      <div className="page-header">
        <NavBarComponent />
      </div>
      <div className="page-container">
        <RouterComponent />
      </div>
      <Footer />

    </>
  );

}

export default App;
