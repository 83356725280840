import "../../css/SnippetComponent.css";

import React, { useState } from "react";

import Card from "react-bootstrap/Card";
import CardEditButtons from "./CardEditButtons";
import FormControl from "react-bootstrap/FormControl";
import MDHelpDialog from "./MDHelpDialog";

const CardEditable = ({ item, stepNumber, setEditable, onConfirmClick }) => {
  var [title, setTitle] = useState(item.title);
  var [step, setStep] = useState(item.step);
  var [showMarkdownHelp, setShowMarkdownHelp] = useState(false);

  if (showMarkdownHelp) {
    //Preserve the title and step before going into the markdown help dialog.
    item.title = title;
    item.step = step;
    return (
      <MDHelpDialog
        onCloseButton={(val) => setShowMarkdownHelp(val)}
      ></MDHelpDialog>
    );
  }
  return (
    <>
      <Card border="info">
        <Card.Header as="h5">
          <FormControl
            placeholder="Title"
            defaultValue={item.title}
            onChange={(event) => setTitle(event.target.value)}
          />
        </Card.Header>
        <Card.Body>
          <Card.Text>
            <FormControl
              as="textarea"
              rows="4"
              placeholder="Details"
              defaultValue={item.step}
              aria-label="Description"
              onChange={(event) => setStep(event.target.value)}
            />
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted">
          <CardEditButtons
            setEditable={setEditable}
            index={stepNumber}
            onConfirmClick={(index) => {
              onConfirmClick(index, title, step);
            }}
            onInfoClick={(val) => setShowMarkdownHelp(val)}
          />
        </Card.Footer>
      </Card>
    </>
  );
};

export default CardEditable;

