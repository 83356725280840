import React from 'react';
import { faTrash, faEdit, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CardViewButtons = ({ setEditable, index, onDeleteClick, onNewClick }) => (
    <>
        <Button variant="outline-primary" onClick={() => setEditable(index, true)} >
            <OverlayTrigger
                placement='bottom'
                overlay={
                    <Tooltip id={`tooltip-edit`}>
                        Edit
                    </Tooltip>
                }
            >
                <FontAwesomeIcon icon={faEdit} size="1x" />
            </OverlayTrigger>
        </Button>{' '}

        <Button variant="outline-success" onClick={() => onNewClick(index)}>
            <OverlayTrigger
                placement='bottom'
                overlay={
                    <Tooltip id={`tooltip-new`}>
                        New
                    </Tooltip>
                }
            >
                <FontAwesomeIcon icon={faAngleDoubleDown} />
            </OverlayTrigger>

        </Button>{' '}

        <Button variant="outline-danger" onClick={() => onDeleteClick(index)}>
            <OverlayTrigger
                placement='bottom'
                overlay={
                    <Tooltip id={`tooltip-delete`}>
                        Delete
                    </Tooltip>
                }
            >
                <FontAwesomeIcon icon={faTrash} />
            </OverlayTrigger>
        </Button>{' '}
    </>
);

export default CardViewButtons;