import { createSelector } from 'reselect';

export const getSnippets = state => state.snippets.data;

export const getSnippetsLoading = state => state.snippets.isLoading;

export const getOneSnippet = state => state.workingSnippet.data;
export const getOneSnippetLoading = state => state.workingSnippet.isLoading;

export const getPublicSnippets = createSelector(
    getSnippets,
    (snippets) => snippets.filter(snippet => snippet.isPublic === true),
);

export const getPrivateSnippets = createSelector(
    getSnippets,
    (snippets) => snippets.filter(snippet => snippet.isPublic === false),
);