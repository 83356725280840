import Modal from 'react-bootstrap/Modal'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { helpText } from './Markdown'

const MDHelpDialog = ({ onCloseButton }) => {
    return (
        <Modal
            size="md"
            show={true}
            onHide={() => onCloseButton(false)}
            centered
            scrollable
            dialogClassName="modal-md-dialog"

        >
            <Modal.Header closeButton>
                <Modal.Title >
                    Markdown Cheat Sheet
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ReactMarkdown>
                    {helpText}
                </ReactMarkdown>
            </Modal.Body>
        </Modal>
    );
}

export default MDHelpDialog;