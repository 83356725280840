import "../css/NavLoginDropdown.css";

import Image from "react-bootstrap/Image";
import NavDropdown from "react-bootstrap/NavDropdown";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const NavLoginDropdown = () => {
  const { user, isLoading, logout } = useAuth0();

  return isLoading ? (
    <div> loading... </div>
  ) : (
    <>
      <Image
        src={user.picture}
        className="profile-pic"
        alt="picture"
        roundedCircle
      />
      <NavDropdown alignRight title={user.nickname} id="basic-nav-dropdown">
        <NavDropdown.Item href="/application/snippets/view">
          My Snippets
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          Logout
        </NavDropdown.Item>
      </NavDropdown>
    </>
  );
};

export default NavLoginDropdown;

