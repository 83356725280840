import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React from "react";
import "../../css/Dialog.css";

const CancelConfirmDialog = ({
  handleConfirm,
  handleClose,
  message,
  title,
}) => {
  return (
    <div className="cancel-confirm-container">
      <Modal show={true} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleConfirm}>
            Proceed
          </Button>
          <Button variant="success" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CancelConfirmDialog;
