import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { Link } from "react-router-dom";

const FooterAuthText = () => {
  const { loginWithRedirect, logout, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div></div>;
  }

  return isAuthenticated ? (
    <>
      <Link to="/" onClick={() => logout({ returnTo: window.location.origin })}>
        Logout
      </Link>
    </>
  ) : (
    <>
      <Link to="/" onClick={() => loginWithRedirect()}>
        Login
      </Link>
      <Link to="/" onClick={() => loginWithRedirect({ screen_hint: "signup" })}>
        Sign Up
      </Link>
    </>
  );
};

export default FooterAuthText;

