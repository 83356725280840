import './index.css';

import * as serviceWorker from './serviceWorker';

import App from './App';
import Auth0ProviderWithHistory from './components/Auth0ProviderWithHistory'
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'
import { configureStore } from './redux/store';

ReactDOM.render(
  <Provider store={configureStore()} >
    <Router>
      <Auth0ProviderWithHistory>

        <App />

      </Auth0ProviderWithHistory>
    </Router>
  </Provider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
