import "../../css/SnippetComponent.css";

import React, { useEffect, useRef, useState } from "react";
import {
  addSnippetRequest,
  loadOneSnippetRequest,
  updateSnippetRequest,
} from "../../redux/thunks";
import { getOneSnippet, getOneSnippetLoading } from "../../redux/selectors";

import Button from "react-bootstrap/Button";
import CancelConfirmDialog from "./CancelConfirmDialog";
import { Redirect } from "react-router-dom";
import SnippetCard from "./SnippetCard";
import SnippetInputs from "./SnippetInputs";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import time_elapsed_string from "../../utils/timeelapsed";

const NewSnippet = ({
  workingSnippet,
  isLoading,
  onCreatePressed,
  snippetId = "",
  startLoadingSnippet,
}) => {
  var [redirect, setRedirect] = useState(false);
  var [confirmCancel, setConfirmCancel] = useState(false);
  var [isDirty, setIsDirty] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (snippetId !== "") {
      startLoadingSnippet(snippetId, getAccessTokenSilently);
    }
  }, [startLoadingSnippet, snippetId, getAccessTokenSilently]);

  const useIsMounted = () => {
    const isMounted = useRef(false);
    useEffect(() => {
      isMounted.current = true;
      return () => (isMounted.current = false);
    }, []);
    return isMounted;
  };

  const defaultObj = {
    title: "",
    description: "",
    tags: [],
    isPublic: false,
    steps: [
      {
        step: "",
        title: "",
      },
    ],
  };
  const isMounted = useIsMounted();
  useEffect(() => {
    const obj = {
      title: "",
      description: "",
      tags: [],
      isPublic: false,
      steps: [
        {
          step: "",
          title: "",
        },
      ],
    };

    // Only if loading is false and still mounted

    if (isLoading === false && isMounted.current) {
      if (
        snippetId !== "" &&
        workingSnippet &&
        snippetId === workingSnippet.id
      ) {
        setSnip(workingSnippet);
      } else setSnip(obj);
    }
  }, [workingSnippet, isMounted, isLoading, snippetId]);

  // Empty object
  var emptyObj = {};
  emptyObj.title = "";
  emptyObj.description = "";
  emptyObj.tags = [];
  emptyObj.isPublic = false;
  emptyObj.steps = [];

  var [snip, setSnip] = useState(defaultObj);

  var tags = "";

  if (redirect) {
    return <Redirect to="/application/snippets/view" />;
  } else if (confirmCancel) {
    return (
      <CancelConfirmDialog
        handleConfirm={() => setRedirect(true)}
        handleClose={() => {
          setRedirect(false);
          setConfirmCancel(false);
        }}
        message="Are you sure you want to proceed without saving?"
        title="Cancel?"
      />
    );
  } else if (isLoading) {
    return <div>Loading...</div>;
  } else {
    var elapsed_string = time_elapsed_string(snip.dateupdated);
    return (
      <div className="app-snippet-container">
        <SnippetInputs
          title={snip.title}
          description={snip.description}
          tags={snip.tags}
          isPublic={snip.isPublic}
          setTitle={(e) => {
            snip.title = e.target.value;
            setIsDirty(true);
          }}
          setDescription={(e) => {
            snip.description = e.target.value;
            setIsDirty(true);
          }}
          setTags={(e) => {
            tags = e.target.value;
            snip.tags = tags.split(",");
            setIsDirty(true);
          }}
          setIsPublic={(val) => {
            snip.isPublic = val;
            setIsDirty(true);
          }}
        />
        <p className="text-right">
          <small>{elapsed_string}</small>
        </p>
        <hr />
        <SnippetCard
          snippetId={snippetId}
          setStepsInParent={(mysteps) => {
            snip.steps = mysteps;
            setIsDirty(true);
          }}
        />
        <hr />
        <div className="btn-group special">
          <Button
            variant="success"
            onClick={() => {
              onCreatePressed(snip, snippetId, getAccessTokenSilently);
              setRedirect(true);
            }}
          >
            Save
          </Button>
          {"   "}
          <Button
            variant="danger"
            onClick={() => {
              if (!isDirty) setRedirect(true);
              else {
                setSnip(snip);
                setConfirmCancel(true);
              }
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  workingSnippet: getOneSnippet(state),
  isLoading: getOneSnippetLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  onCreatePressed: (snip, snippetId, getAccessTokenSilently) => {
    if (snippetId === "")
      dispatch(addSnippetRequest(snip, getAccessTokenSilently));
    else
      dispatch(
        updateSnippetRequest(
          {
            snippetId: snippetId,
            snip: snip,
          },
          getAccessTokenSilently
        )
      );
  },
  startLoadingSnippet: (snippetId, getAccessTokenSilently) => {
    dispatch(loadOneSnippetRequest(snippetId, getAccessTokenSilently));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewSnippet);
