import '../../css/SnippetComponent.css'

import React, { useState } from 'react'

import ButtonGroup from 'react-bootstrap/ButtonGroup'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import ToggleButton from 'react-bootstrap/ToggleButton'
import Tooltip from 'react-bootstrap/Tooltip'

const SnippetInputs = ({ title, description, tags, isPublic, setTitle, setDescription, setTags, setIsPublic }) => {

  const radios = [
    { name: 'Private', value: '1' },
    { name: 'Public', value: '2' },
  ];
  const [radioValue, setRadioValue] = useState(isPublic === true ? '2' : '1');



  return (
    //item = snippet[0];
    <>
      <div className="input-container">

        <InputGroup className="my-4">
          <OverlayTrigger
            placement='auto'
            overlay={
              <Tooltip id={`tooltip-title`}>
                Enter a title that briefly describes this Snippet.
             </Tooltip>
            }
          >
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroup-sizing-default">Title</InputGroup.Text>
            </InputGroup.Prepend>
          </OverlayTrigger>
          <FormControl
            aria-label="Title"
            aria-describedby="inputGroup-sizing-default"
            defaultValue={title}
            placeholder="Enter a title for the Snippet"
            onChange={setTitle}
          />
        </InputGroup>

        <InputGroup className="my-4">
          <OverlayTrigger
            placement='auto'
            overlay={
              <Tooltip id={`tooltip-title`}>
                Describe how this Snippet will be useful to a fellow security person.
             </Tooltip>
            }
          >
            <InputGroup.Prepend>
              <InputGroup.Text>Description</InputGroup.Text>
            </InputGroup.Prepend>
          </OverlayTrigger>
          <FormControl as="textarea"
            rows="4"
            aria-label="Description"
            defaultValue={description}
            placeholder="Describe what the Snippet does and how it helps"
            onChange={setDescription}
          />
        </InputGroup>


        <InputGroup className="my-4">
          <OverlayTrigger
            placement='auto'
            overlay={
              <Tooltip id={`tooltip-title`}>
                Enter tags that make it easy to search and organize this Snippet.
             </Tooltip>
            }
          >
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroup-sizing-default">Tags</InputGroup.Text>
            </InputGroup.Prepend>
          </OverlayTrigger>
          <FormControl
            aria-label="Tags"
            aria-describedby="inputGroup-sizing-default"
            defaultValue={tags}
            placeholder="security, AppSec, authentication"
            onChange={setTags}
          />
        </InputGroup>
        <OverlayTrigger
          placement='auto'
          overlay={
            <Tooltip id={`tooltip-title`}>
              Everyone can see a public Snippet.  Only you can view a private Snippet.
             </Tooltip>
          }
        >
          <ButtonGroup toggle >
            {radios.map((radio, idx) => (
              <ToggleButton

                key={idx}
                type="radio"
                variant="success"
                name="radio"
                value={radio.value}
                defaultValue={isPublic}
                checked={radio.value === radioValue}
                onChange={(e) => {
                  setRadioValue(e.currentTarget.value)
                  setIsPublic(e.currentTarget.value === "2" ? true : false)
                }}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </OverlayTrigger>
      </div>
    </>
  );
}
export default SnippetInputs;