import {
  createSnippet,
  loadOneSnippetFailure,
  loadOneSnippetInProgress,
  loadOneSnippetSuccess,
  loadSnippetsFailure,
  loadSnippetsInProgress,
  loadSnippetsSuccess,
  removeSnippet,
  updateSnippet,
} from "./actions";

export const loadSnippets = (getAccessTokenSilently) => async (
  dispatch,
  getState
) => {
  const serverUrl = process.env.REACT_APP_APPSERVER_URL;
  const token = await getAccessTokenSilently();

  try {
    dispatch(loadSnippetsInProgress());
    const response = await fetch(`${serverUrl}/api/v1/snippets?list=min`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { message } = await response.json();
    dispatch(loadSnippetsSuccess(message));
  } catch (e) {
    dispatch(loadSnippetsFailure());
    console.log(e);
  }
};

export const addSnippetRequest = (snip, getAccessTokenSilently) => async (
  dispatch,
  getState
) => {
  try {
    const serverUrl = process.env.REACT_APP_APPSERVER_URL;
    const token = await getAccessTokenSilently();
    const response = await fetch(`${serverUrl}/api/v1/snippets`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
      method: "post",
      body: JSON.stringify(snip),
    });
    const snippet = await response.json();
    dispatch(createSnippet(snippet));
  } catch (e) {
    console.log(e);
  }
};

export const removeSnippetRequest = (id, getAccessTokenSilently) => async (
  dispatch,
  getState
) => {
  try {
    const serverUrl = process.env.REACT_APP_APPSERVER_URL;
    const token = await getAccessTokenSilently();
    const response = await fetch(`${serverUrl}/api/v1/snippets/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
      method: "delete",
    });
    const removedSnippet = await response.json();
    dispatch(removeSnippet(removedSnippet));
  } catch (e) {
    console.log(e);
  }
};

export const updateSnippetRequest = (snip, getAccessTokenSilently) => async (
  dispatch,
  getState
) => {
  try {
    const serverUrl = process.env.REACT_APP_APPSERVER_URL;
    const token = await getAccessTokenSilently();
    const id = snip.snip.id;
    const response = await fetch(`${serverUrl}/api/v1/snippets/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
      method: "put",
      body: JSON.stringify(snip.snip),
    });
    const updatedSnippet = await response.json();
    dispatch(updateSnippet(updatedSnippet, id));
  } catch (e) {
    console.log(e);
  }
};

export const loadOneSnippetRequest = (id, getAccessTokenSilently) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadOneSnippetInProgress());
    const serverUrl = process.env.REACT_APP_APPSERVER_URL;
    const token = await getAccessTokenSilently();
    const response = await fetch(`${serverUrl}/api/v1/snippets/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
      method: "get",
    });
    const oneSnippet = await response.json();
    dispatch(loadOneSnippetSuccess(oneSnippet));
  } catch (e) {
    dispatch(loadOneSnippetFailure());
    console.log(e);
  }
};
