// This function will take in a ISO 8601 string and return a string
// that specifies how much time has elapsed between now and the date in
// the string.
// It will overflow to the next unit seconds -> minutes -> hours -> days.
// It will also take care of plural in the final string
//      eg. 1 second vs 2 seconds

function time_elapsed_string(timestamp) {
  var elapsed_string = "";
  var elapsed_time = 0;
  if (timestamp !== undefined) {
    var date_updated = new Date(timestamp);
    var now = new Date();
    var seconds_elapsed = (now.getTime() - date_updated.getTime()) / 1000;
    if (seconds_elapsed >= 60) {
      var minutes_elapsed = seconds_elapsed / 60;
      if (minutes_elapsed >= 60) {
        var hours_elapsed = minutes_elapsed / 60;
        if (hours_elapsed >= 24) {
          var days_elapsed = hours_elapsed / 24;
          elapsed_time = Math.round(days_elapsed);
          elapsed_string = "last updated: " + elapsed_time + " day";
        } else {
          elapsed_time = Math.round(hours_elapsed);
          elapsed_string = "last updated: " + elapsed_time + " hour";
        }
      } else {
        elapsed_time = Math.round(minutes_elapsed);

        elapsed_string = "last updated: " + elapsed_time + " minute";
      }
    } else {
      elapsed_time = Math.round(seconds_elapsed);

      elapsed_string = "last updated: " + elapsed_time + " second";
    }
    if (elapsed_string !== "") {
      if (elapsed_time > 1) elapsed_string += "s ago";
      else elapsed_string += " ago";
    }
  }
  return elapsed_string;
}

export default time_elapsed_string;
