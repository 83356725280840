import React from 'react';
import LoginButton from '../buttons/LoginButton';
import LogoutButton from '../buttons/LogoutButton';


const LoginPage = () => (
    <>
        <h1>Login here</h1>
        <p>this is great</p>
        <LoginButton/>
        <LogoutButton/>
        
    </>
);

export default LoginPage;