import "../../css/Application.css";

import AppSideMenu from "../AppSideMenu";
import NewSnippet from "../snippet/NewSnippet";
import React, { useState } from "react";
import SnippetList from "../snippet/SnippetList";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { FaBars } from "react-icons/fa";
const queryString = require("query-string");

const ApplicationComponent = (props) => {
  const [toggled, setToggled] = useState(false);
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  var err = undefined;
  var err_message = undefined;
  const location = useLocation();
  if (location !== undefined) {
    const params = queryString.parse(location.search);
    err_message = params.error_description;
    err = params.error;
  }

  const { logout } = useAuth0();
  if (err !== undefined) {
    return (
      <div>
        <h2 style={{ color: "red" }}>
          <br />
          Error - {err} - {err_message}
        </h2>
        {logout()}
      </div>
    );
  }
  switch (props.action) {
    case "listSnippets": {
      return (
        <div className="application-container">
          <AppSideMenu
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
          />
          <div className="app-content-container">
            <div
              className="btn-toggle"
              onClick={() => handleToggleSidebar(true)}
            >
              <FaBars />
            </div>
            <SnippetList />
          </div>
        </div>
      );
    }

    case "newSnippet": {
      return (
        <div className="application-container">
          <AppSideMenu
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
          />
          <div className="app-content-container">
            <div
              className="btn-toggle"
              onClick={() => handleToggleSidebar(true)}
            >
              <FaBars />
            </div>
            <NewSnippet />
          </div>
        </div>
      );
    }
    case "editSnippet": {
      const snippetId = props.match.params.snippetId;
      return (
        <div className="application-container">
          <AppSideMenu
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
          />
          <div className="app-content-container">
            <div
              className="btn-toggle"
              onClick={() => handleToggleSidebar(true)}
            >
              <FaBars />
            </div>
            <NewSnippet snippetId={snippetId} />
          </div>
        </div>
      );
    }

    default: {
      return (
        <div className="application-container">
          <AppSideMenu
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
          />
          <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
            <FaBars />
          </div>
        </div>
      );
    }
  }
};

export default ApplicationComponent;
