import React from "react";
import "../css/Footer.css";
import { Link } from "react-router-dom";
import FooterAuthText from "./FooterAuthText";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer">
        <div className="footer-heading footer-1">
          <h5>Site Navigation</h5>
          <Link to="/">Home</Link>
          <Link to="/pricing">Pricing</Link>
          <FooterAuthText />
        </div>
        <div className="footer-heading footer-2">
          <h5>Learn More</h5>
          <Link to="/changelog">Changelog</Link>
          <Link to="/about">Blog</Link>
          <Link to="/about">About Us</Link>
          <a href="mailto:secsynergyio@gmail.com" className="footer-link">
            Contact
          </a>
        </div>
        <div className="d-none d-md-block">
          <div className="footer-heading footer-3">
            <h5>Social Media</h5>
            <Link to="/changelog">Twitter</Link>
            <Link to="/changelog">LinkedIn</Link>
            <Link to="/changelog">Facebook</Link>
          </div>
        </div>
      </div>
      <hr />
      <div className="footer-copyright">
        &copy;{new Date().getFullYear()} Security Synergy | All rights reserved
        | Terms of Conditions | Privacy
      </div>
    </div>
  );
};

export default Footer;
