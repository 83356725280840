import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import CancelConfirmDialog from "./CancelConfirmDialog";
import Tooltip from "react-bootstrap/Tooltip";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../css/SnippetList.css";

import React, { useEffect, useState } from "react";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  getPrivateSnippets,
  getPublicSnippets,
  getSnippetsLoading,
} from "../../redux/selectors";

import Badge from "react-bootstrap/Badge";
import BootstrapTable from "react-bootstrap-table-next";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { loadSnippets, removeSnippetRequest } from "../../redux/thunks";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useAuth0 } from "@auth0/auth0-react";

const SnippetList = ({
  publicSnippets,
  privateSnippets,
  isLoading,
  startLoadingSnippets,
  onDeletePressed,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    startLoadingSnippets(getAccessTokenSilently);
  }, [startLoadingSnippets, getAccessTokenSilently]);

  const { SearchBar } = Search;
  var [redirect, setRedirect] = useState("");

  var [confirmDelete, setConfirmDelete] = useState(0);
  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "25 %", textAlign: "left" };
      },
    },
    {
      dataField: "description",
      text: "Description",
      style: (cell, row, rowIndex, colIndex) => {
        return {
          whiteSpace: "normal",
          textOverflow: "ellipsis",
          overflow: "hidden",
        };
      },
    },
    {
      dataField: "tags",
      text: "Tags",
      headerStyle: (colum, colIndex) => {
        return { width: "30%", textAlign: "left" };
      },
      isDummyField: true,
      formatter: (cellContent, row) => {
        return (
          <React.Fragment key={count++}>
            {row.tags.map((item, index) => (
              <React.Fragment key={count++}>
                <Badge pill variant="primary" key={count++}>
                  {item}
                </Badge>{" "}
                {index % 2 === 1 ? <br /> : null}
              </React.Fragment>
            ))}
          </React.Fragment>
        );
      },
    },
  ];

  // const selectRow = {
  //   clickToExpand: true,
  // };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <Button
          variant="success"
          onClick={() => {
            setRedirect(row.id);
          }}
        >
          {"  "}Edit{"   "}
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id={`tooltip-edit`}>Edit</Tooltip>}
          >
            <FontAwesomeIcon icon={faEdit} size="1x" />
          </OverlayTrigger>
        </Button>
        {"    "}
        <Button
          variant="danger"
          onClick={() => {
            setConfirmDelete(row.id);
          }}
        >
          Delete{" "}
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id={`tooltip-delete`}>Delete</Tooltip>}
          >
            <FontAwesomeIcon icon={faTrash} size="1x" />
          </OverlayTrigger>
        </Button>
        {"    "}
      </div>
    ),
  };
  const paginationOptions = {
    sizePerPage: 5,
    hideSizePerPage: true,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    hidePageListOnlyOnePage: true,
  };

  /*const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setRedirect(row.id);
    },
  };*/

  var count = 0;
  if (redirect !== "") {
    return <Redirect to={"/application/snippet/" + redirect} />;
  }
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (confirmDelete !== 0) {
    return (
      <CancelConfirmDialog
        handleConfirm={() => {
          onDeletePressed(confirmDelete, getAccessTokenSilently);
          setConfirmDelete(0);
        }}
        handleClose={() => {
          setConfirmDelete(0);
        }}
        message="Are you sure you want to delete this snippet? Note: any forked snippet will still be visible to users and will not be deleted"
        title="Delete?"
      />
    );
  } else {
    return (
      <div className="app-snippet-list-container">
        <h4>Public Snippets</h4>
        <ToolkitProvider
          keyField="id"
          bootstrap4
          data={publicSnippets}
          columns={columns}
          pagination={paginationFactory(paginationOptions)}
          search
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <BootstrapTable
                pagination={paginationFactory(paginationOptions)}
                {...props.baseProps}
                hover
                // rowEvents={rowEvents}
                expandRow={expandRow}
                key={count++}
              />
            </div>
          )}
        </ToolkitProvider>
        <h4>Private Snippets</h4>
        <ToolkitProvider
          keyField="id"
          bootstrap4
          data={privateSnippets}
          columns={columns}
          pagination={paginationFactory(paginationOptions)}
          search
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <BootstrapTable
                pagination={paginationFactory(paginationOptions)}
                {...props.baseProps}
                hover
                // rowEvents={rowEvents}
                expandRow={expandRow}
                key={count++}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  publicSnippets: getPublicSnippets(state),
  privateSnippets: getPrivateSnippets(state),
  //snippets: getSnippets(state),
  isLoading: getSnippetsLoading(state),
});
const mapDispatchToProps = (dispatch) => ({
  onDeletePressed: (snippetId, getAccessTokenSilently) => {
    dispatch(removeSnippetRequest(snippetId, getAccessTokenSilently));
  },
  startLoadingSnippets: (getAccessTokenSilently) =>
    dispatch(loadSnippets(getAccessTokenSilently)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SnippetList);
