import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Button from 'react-bootstrap/Button'

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();
  
    return <Button onClick={() => loginWithRedirect({ screen_hint: 'signup' })} variant="dark">Sign Up</Button>;
  };

export default LoginButton;