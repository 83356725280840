import {
    CREATE_SNIPPET,
    LOAD_ONE_SNIPPET_FAILURE,
    LOAD_ONE_SNIPPET_IN_PROGRESS,
    LOAD_ONE_SNIPPET_SUCCESS,
    LOAD_SNIPPETS_FAILURE,
    LOAD_SNIPPETS_IN_PROGRESS,
    LOAD_SNIPPETS_SUCCESS,
    REMOVE_SNIPPET,
    UPDATE_SNIPPET
} from './actions'

const initialState = {
    isLoading: false,
    data: [],
};

export const snippets = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case CREATE_SNIPPET: {
            const { snippet } = payload;
            const { message } = snippet;
            return {
                ...state,
                data: state.data.concat(message),
            };
        }
        case UPDATE_SNIPPET: {
            const { snippet } = payload;
            const { message } = snippet;
            const { id } = payload;
            const snips = state.data;
            const objIndex = snips.findIndex((obj => obj.id === id));
            snips[objIndex] = message;
            return {
                ...state,
                data: snips,
            }
        }
        case REMOVE_SNIPPET: {
            const { snippet: snippetToRemove } = payload;
            return {
                ...state,
                data: state.data.filter(snippet => snippet.id !== snippetToRemove.id),
            };
        }


        case LOAD_SNIPPETS_SUCCESS: {
            const { snippets } = payload;
            return {
                ...state,
                isLoading: false,
                data: snippets,
            }
        }
        case LOAD_SNIPPETS_IN_PROGRESS:
            return {
                ...state,
                isLoading: true,
            }
        case LOAD_SNIPPETS_FAILURE:
            return {
                ...state,
                isLoading: false,
            }

        default:
            return state;

    }
}

const initialOneSnippetState = {
    isLoading: false,
    data: {
        steps: [],
    },
};

export const workingSnippet = (state = initialOneSnippetState, action) => {
    const { type, payload } = action;

    switch (type) {

        case LOAD_ONE_SNIPPET_SUCCESS: {
            const { snippet } = payload;
            const { message } = snippet;
            return {
                ...state,
                isLoading: false,
                data: message,
            }
        }

        case LOAD_ONE_SNIPPET_IN_PROGRESS:
            return {
                isLoading: true,
                data: {}
            }

        case LOAD_ONE_SNIPPET_FAILURE:
            return {
                ...state,
                isLoading: false,
            }

        default:
            return state;

    }
}

