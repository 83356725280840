


export const UPDATE_SNIPPET = 'UPDATE_SNIPPET';
export const updateSnippet = (snippet, id) => ({
    type: UPDATE_SNIPPET,
    payload: {
        snippet,
        id,
    },
});

export const CREATE_SNIPPET = 'CREATE_SNIPPET';
export const createSnippet = (snippet) => ({
    type: CREATE_SNIPPET,
    payload: { snippet }

});

export const REMOVE_SNIPPET = 'REMOVE_SNIPPET';
export const removeSnippet = (snippet) => ({
    type: REMOVE_SNIPPET,
    payload: { snippet }

});

export const LOAD_SNIPPETS_IN_PROGRESS = 'LOAD_SNIPPETS_IN_PROGRESS';
export const loadSnippetsInProgress = () => ({
    type: LOAD_SNIPPETS_IN_PROGRESS
});

export const LOAD_SNIPPETS_SUCCESS = 'LOAD_SNIPPETS_SUCCESS';
export const loadSnippetsSuccess = snippets => ({
    type: LOAD_SNIPPETS_SUCCESS,
    payload: { snippets }
});

export const LOAD_SNIPPETS_FAILURE = 'LOAD_SNIPPETS_FAILURE';
export const loadSnippetsFailure = () => ({
    type: LOAD_SNIPPETS_FAILURE,
});


export const LOAD_ONE_SNIPPET_IN_PROGRESS = 'LOAD_ONE_SNIPPET_IN_PROGRESS';
export const loadOneSnippetInProgress = () => ({
    type: LOAD_ONE_SNIPPET_IN_PROGRESS
});

export const LOAD_ONE_SNIPPET_SUCCESS = 'LOAD_ONE_SNIPPET_SUCCESS';
export const loadOneSnippetSuccess = snippet => ({
    type: LOAD_ONE_SNIPPET_SUCCESS,
    payload: { snippet }
});

export const LOAD_ONE_SNIPPET_FAILURE = 'LOAD_ONE_SNIPPET_FAILURE';
export const loadOneSnippetFailure = () => ({
    type: LOAD_ONE_SNIPPET_FAILURE,
});