import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useAuth0 } from "@auth0/auth0-react";

import AuthenticationButton from "./buttons/AuthenticationButton";
import logo from "../logo.png";

const NavBarComponent = () => {
  const { isAuthenticated } = useAuth0();
  const homeLink = isAuthenticated ? "/application" : "/";

  return (
    <>
      <Navbar expand="lg" bg="dark" variant="dark">
        <Navbar.Brand href={homeLink}>
          <img src={logo} className="App-logo" alt="logo" />
          &nbsp; Security Synergy
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav>
            <AuthenticationButton />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavBarComponent;
