import "../css/prosidebar.scss";

import { FaHome, FaKey, FaToolbox } from "react-icons/fa";
import { Menu, MenuItem, ProSidebar, SubMenu } from "react-pro-sidebar";

import { Link } from "react-router-dom";
import React from "react";

const AppSideMenu = ({ toggled, handleToggleSidebar }) => {
  return (
    <div className="sidenav">
      <ProSidebar
        toggled={toggled}
        breakPoint="md"
        onToggle={handleToggleSidebar}
      >
        <Menu iconShape="square">
          <MenuItem icon={<FaHome />}>Home</MenuItem>
          <SubMenu title="Security Snippets" icon={<FaKey />}>
            <MenuItem>
              <Link to="/application/snippets/view">My Snippets </Link>
            </MenuItem>
            <MenuItem>
              <Link to="/application/snippet/new">New Snippet</Link>
            </MenuItem>
            <MenuItem>Explore Snippets</MenuItem>
          </SubMenu>
          <SubMenu title="Tools" icon={<FaToolbox />}>
            <MenuItem>Component 1</MenuItem>
            <MenuItem>Component 2</MenuItem>
          </SubMenu>
        </Menu>
      </ProSidebar>
    </div>
  );
};

export default AppSideMenu;
