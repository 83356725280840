
import React from "react";

import LoginButton from "./LoginButton";

import {useAuth0} from "@auth0/auth0-react";

import SignupButton from "./SignupButton";
import NavLoginDropdown from "../NavLoginDropdown";

const AuthenticationButton = () => {

  const { isAuthenticated, isLoading } = useAuth0();

  
  if (isLoading) {
    return <div>Loading</div>;
  }



  return (
    isAuthenticated ?

      <>  <NavLoginDropdown/> </> :
      <> <LoginButton /> <SignupButton/> </>
  
  )
};



export default AuthenticationButton;