import { Route, Switch } from "react-router-dom";

import AboutPage from "./pages/AboutPage";
import Application from "./pages/Application";
import ChangelogPage from "./pages/ChangelogPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./unused/LoginPage";
import NotFoundPage from "./pages/NotFoundPage";
import PricingPage from "./pages/PricingPage";
import ProtectedRoute from "./ProtectedRoute";
import React from "react";
import SignUpPage from "./unused/SignUpPage";
import { BrowserRouter } from "react-router-dom";

class DebugRouter extends BrowserRouter {
  constructor(props) {
    super(props);
    console.log("initial history is: ", JSON.stringify(this.history, null, 2));
    this.history.listen((location, action) => {
      console.log(
        `The current URL is ${location.pathname}${location.search}${location.hash}`
      );
      console.log(
        `The last navigation action was ${action}`,
        JSON.stringify(this.history, null, 2)
      );
    });
  }
}

const RouterComponent = (props) => {
  return (
    <>
      <DebugRouter>
        <Switch>
          <Route path="/" component={HomePage} exact />
          <Route path="/home" component={HomePage} exact />
          <Route path="/about" component={AboutPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/signup" component={SignUpPage} />
          <Route path="/changelog" component={ChangelogPage} />
          <Route path="/pricing" component={PricingPage} />
          <Route
            path="/application"
            component={(props) => <Application {...props} />}
            exact
          />
          <ProtectedRoute
            path="/application/snippets/view"
            component={() => <Application action="listSnippets" />}
          />

          <ProtectedRoute
            path="/application/snippet/new"
            component={() => <Application action="newSnippet" />}
          />
          <ProtectedRoute
            path="/application/snippet/:snippetId"
            component={(props) => (
              <Application {...props} action="editSnippet" />
            )}
            exact
          />
          <Route component={NotFoundPage} />
        </Switch>
      </DebugRouter>
    </>
  );
};

export default RouterComponent;
